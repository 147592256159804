@mixin title-style {
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	font-size: 24px;
	font-weight: 500;
	padding: 15px 10px 13px 10px;
	border-radius: 5px;

	@media (max-width: 1690px) {
		font-size: 20px;
		padding: 10px 10px 8px 10px;
	}
}

.paytable {
	padding-top: 60px;

	&--content {
		background-color: var(--quaternary-color);
		border-radius: 5px;

		.row {
			margin: 0;
		}

		.col {
			padding: 0 5px;
		}

		&-title {
			background: var(--quaternary-gradient);
			@include title-style;
		}

		&-container {
			padding: 15px 10px 23px 10px;

			&-subtitle {
				color: #fff;
				text-align: center;
				text-transform: uppercase;
				font-weight: 500;
				font-size: 16px;
				line-height: 16px;
				margin-top: 20px;

				span {
					text-transform: none;
					font-weight: bolder;
				}

				@media (max-width: 1470px) {
					font-size: 15px;
					line-height: 15px;
					margin-top: 15px;
				}
			}

			&-block {
				background: #000;
				color: #fff;
				text-align: center;
				font-size: 20px;
				text-transform: uppercase;
				border-radius: 5px;
				padding: 10px 5px 9px 5px;
				margin: 10px 0 0 0;
				font-weight: 500;

				&.red {
					border: 1px solid #fff;
					background: #e0121b;
				}

				&.black {
					border: 1px solid #fff;
					background: #181015;
				}

				&-special {
					width: 100%;
					max-width: 265px;
					margin: 0 auto;
				}

				@media (max-width: 1690px) {
					font-size: 18px;
					padding: 5px 5px 4px 5px;
				}

				@media (max-width: 1470px) {
					font-size: 15px;
				}
			}

			@media (max-width: 1690px) {
				padding: 10px 10px 13px 10px;
			}
		}
	}

	@media (max-width: 991px) {
		padding: 30px 10px;
	}

	@media (max-width: 575px) {
		padding: 10px 10px;
	}
}
