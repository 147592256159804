:root {
	--primary-font-family: "Poppins", -apple-system, BlinkMacSystemFont, Roboto,
		Helvetica Neue, sans-serif;

	--secondary-font-family: "Roboto Condensed", -apple-system,
		BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
	--font-size-xsmall: 9px;
	--font-size-small: 12px;
	--font-size: 14px;
	--font-size-medium: 16px;
	--font-size-large: 20px;
	--font-size-xlarge: 25px;
	--bg-color: #000;
	--primary-color: #1e1e1e;
	--secondary-color: #260d0d;
	--tertiary-color: #022632;
	--quaternary-color: #161616;
	--quinary-color: #0c1e11;

	--primary-gradient: linear-gradient(
		to bottom,
		rgba(37, 92, 45, 1) 0%,
		rgba(20, 128, 63, 1) 100%
	);
	--secondary-gradient: linear-gradient(
		to bottom,
		rgba(107, 13, 15, 1) 0%,
		rgba(179, 31, 36, 1) 100%
	);
	--tertiary-gradient: linear-gradient(
		to bottom,
		rgba(0, 65, 86, 1) 0%,
		rgba(5, 130, 168, 1) 100%
	);

	--quaternary-gradient: linear-gradient(
		to bottom,
		rgba(35, 35, 35, 1) 0%,
		rgba(67, 68, 68, 1) 100%
	);
}

.bg-white {
	background: var(--white);
}

.primary-color {
	color: var(--primary-color);
}

.font-size-small {
	font-size: var(--font-size-small);
}

.font-size-medium {
	font-size: var(--font-size-medium);
}

.font-size-large {
	font-size: var(--font-size-large);
}

.font-weight-bold {
	font-weight: bold;
}

.font-weight-medium {
	font-weight: 500;
}

.font-weight-regular {
	font-weight: 400;
}
