.header {
	background: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 1) 0%,
		rgba(34, 34, 34, 1) 100%
	);
	color: #fff;
	height: 115px;
	padding: 0 4%;

	&--logo {
		height: 115px;
		position: relative;

		svg {
			max-width: 158px;
			width: 100%;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);

			@media (max-width: 575px) {
				top: auto;
				transform: none;
				position: relative;
				padding-top: 17px;
			}
		}

		@media (max-width: 575px) {
			text-align: center;
		}
	}

	&--mobile-timer {
		display: none;
		text-align: center;
		font-size: 20px;
		width: 100%;
		max-width: 260px;
		float: right;
		height: 33px;
		background: var(--primary-gradient);
		border-radius: 52px;
		padding-top: 3px;
		margin-top: 5px;

		span {
			font-weight: 700;
			font-family: var(--secondary-font-family);
			padding: 0px 0 0 5px;
			font-size: 25px;
			line-height: 26px;
			position: relative;
			vertical-align: bottom;
			display: inline-block;
		}

		@media (max-width: 991px) {
			display: block;
		}

		@media (max-width: 575px) {
			float: none;
			margin: 3px auto 0 auto;
		}
	}

	&--current-draw {
		text-transform: uppercase;
		text-align: center;
		font-size: 35px;
		padding: 35px 95px 0 0;

		span {
			font-weight: 700;
			font-family: var(--secondary-font-family);
			padding: 3px 10px 0 10px;
			font-size: 45px;
			line-height: 45px;
			position: absolute;
		}

		@media (max-width: 1690px) {
			font-size: 25px;
			padding: 44px 95px 0 0;

			span {
				font-size: 35px;
				line-height: 35px;
				padding: 0 10px 0 10px;
			}
		}

		@media (max-width: 991px) {
			padding: 23px 0px 0 0;
			text-align: right;

			span {
				position: relative;
				vertical-align: bottom;
				display: inline-block;
				padding: 0 0 0 10px;
			}
		}

		@media (max-width: 575px) {
			padding: 3px 0px 0 0;
			text-align: center;
			font-size: 20px;

			span {
				font-size: 27px;
				line-height: 27px;
			}
		}
	}

	&--timer {
		@media (max-width: 991px) {
			display: none;
		}

		&-container {
			margin-left: auto;
			width: 100%;
			max-width: 350px;
			height: 52px;
			background: var(--primary-gradient);
			border-radius: 52px;
			margin-top: 31px;
			padding: 0 25px;

			&-icon {
				max-width: 39px;
				width: 100%;
				margin-top: -7px;
				display: inline-block;

				&.animation {
					-webkit-backface-visibility: hidden;
					-webkit-transform: translateZ(0) scale(1, 1);
					animation: rotating 1.5s ease-in-out;
				}
			}

			&-copy {
				font-size: 22px;
				text-transform: uppercase;
				display: inline-block;
				vertical-align: top;
				padding: 11px 0 0 19px;

				@media (max-width: 1470px) {
					font-size: 17px;
					padding: 14px 0 0 19px;
				}
			}

			&-time {
				display: inline-block;
				vertical-align: top;
				font-size: 45px;
				line-height: 45px;
				font-weight: bold;
				padding: 4px 0 0 10px;
				position: absolute;
				font-family: var(--secondary-font-family);

				@media (max-width: 1470px) {
					font-size: 35px;
					line-height: 35px;
					padding: 9px 0 0 10px;
				}
			}

			@media (max-width: 1470px) {
				max-width: 300px;
			}
		}
	}

	@media (max-width: 575px) {
		height: 197px;
	}
}

@keyframes rotating {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
