// Base
@import "./variables.scss";
@import "./helpers.scss";

html {
	display: block;
}

#__website {
	overflow: hidden;
}

#__website,
body {
	font-family: var(--primary-font-family);
	font-size: var(--font-size);
	background: var(--bg-color);
	line-height: 1.43;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	display: block !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&.no-scroll {
		overflow: hidden;
	}
}

*,
*:before,
*:after {
	box-sizing: border-box;
}

img,
svg {
	max-width: 100%;
	display: inline-block;
}

p {
	margin: 0;
	font-size: var(--font-size);
	color: var(--tertiary-neutral);
	&.smaller {
		font-size: var(--font-size-small);
	}
	&.bigger {
		font-size: var(--font-size-large);
	}
}

a {
	color: var(--primary-accent);
	text-decoration: none;
	transition: all 0.3s ease-in-out;
	&:hover {
		color: var(--primary-accent-hover);
	}
}

hr {
	border: solid var(--secondary-neutral);
	border-width: 1px 0 0;
	height: 0;
	margin: 0;
	&.dark {
		border: solid rgba(23, 23, 23, 0.6);
		border-width: 1px 0 0;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
h7,
h8,
h9 {
	margin: 0;
	padding: 0;
	font-weight: bold;
}

h1 {
	font-size: 35px;
}

h2 {
	font-size: 30px;
}

h3 {
	font-size: 25px;
}

h4 {
	font-size: 22px;
}

h5 {
	font-size: 20px;
}

h6 {
	font-size: 18px;
}

input,
select,
textarea {
	font-size: var(--font-size);
	@media screen and (max-width: 767px) {
		font-size: var(--font-size-medium);
	}
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.grecaptcha-badge {
	visibility: hidden;
}

// Plugins
@import "../plugins/bootstrap/bootstrap.scss";
