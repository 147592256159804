html,
body {
	height: 100%;
	margin: 0;
}

body,
.fullscreen-enabled {
	background-image: url("static/images/background.jpg");
	background-size: cover;
	background-position: center bottom;
	background-attachment: fixed;
}

.megawinner {
	&--container {
		padding: 0 4%;

		&-animation {
			margin-top: 5px;
			padding: 0 50px;

			iframe {
				border: 0;
			}

			@media (max-width: 1470px) {
				padding: 0 20px;
			}

			@media (max-width: 1199px) {
				padding: 0 50px 0 0;
			}

			@media (max-width: 991px) {
				padding: 0 20px;
			}

			@media (max-width: 575px) {
				padding: 0;
			}
		}

		@media (max-width: 1470px) {
			padding: 0 3%;
		}
	}
}
